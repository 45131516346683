export const currency_pairs = [
  {
    symbolName: "AUDCAD",
    symbolRate: "0.91085",
    id: "rateAUDCAD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "AUDCHF",
    symbolRate: "0.59754",
    id: "rateAUDCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "AUDJPY",
    symbolRate: "106.849",
    id: "rateAUDJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "AUDNZD",
    symbolRate: "1.092606734294084",
    id: "rateAUDNZD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "AUDSGD",
    symbolRate: "0.9025",
    id: "rateAUDSGD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "AUDUSD",
    symbolRate: "0.6647",
    id: "rateAUDUSD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "CADCHF",
    symbolRate: "0.65597",
    id: "rateCADCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "CADJPY",
    symbolRate: "117.3",
    id: "rateCADJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "CHFJPY",
    symbolRate: "178.81",
    id: "rateCHFJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "CHFSGD",
    symbolRate: "1.511116883116883",
    id: "rateCHFSGD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURAUD",
    symbolRate: "1.61059",
    id: "rateEURAUD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURCAD",
    symbolRate: "1.46711",
    id: "rateEURCAD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURCHF",
    symbolRate: "0.96242",
    id: "rateEURCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURCZK",
    symbolRate: "25.0281",
    id: "rateEURCZK",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURGBP",
    symbolRate: "0.84692",
    id: "rateEURGBP",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURHUF",
    symbolRate: "396.274",
    id: "rateEURHUF",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURJPY",
    symbolRate: "172.098",
    id: "rateEURJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "EURMXN",
    symbolRate: "19.7399",
    id: "rateEURMXN",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURNOK",
    symbolRate: "11.40893",
    id: "rateEURNOK",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURNZD",
    symbolRate: "1.7598",
    id: "rateEURNZD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURPLN",
    symbolRate: "4.3092",
    id: "rateEURPLN",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURSEK",
    symbolRate: "11.37535",
    id: "rateEURSEK",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURSGD",
    symbolRate: "1.45445",
    id: "rateEURSGD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURTRY",
    symbolRate: "35.15297",
    id: "rateEURTRY",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURUSD",
    symbolRate: "1.07108",
    id: "rateEURUSD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "EURZAR",
    symbolRate: "19.76414",
    id: "rateEURZAR",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPAUD",
    symbolRate: "1.9012",
    id: "rateGBPAUD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPCAD",
    symbolRate: "1.73215",
    id: "rateGBPCAD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPCHF",
    symbolRate: "1.13633",
    id: "rateGBPCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPJPY",
    symbolRate: "203.199",
    id: "rateGBPJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "GBPMXN",
    symbolRate: "23.307",
    id: "rateGBPMXN",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPNOK",
    symbolRate: "13.4695",
    id: "rateGBPNOK",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPNZD",
    symbolRate: "2.07776",
    id: "rateGBPNZD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPSEK",
    symbolRate: "13.43",
    id: "rateGBPSEK",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPSGD",
    symbolRate: "1.71716",
    id: "rateGBPSGD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPTRY",
    symbolRate: "41.50488",
    id: "rateGBPTRY",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "GBPUSD",
    symbolRate: "1.26457",
    id: "rateGBPUSD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "NOKJPY",
    symbolRate: "15.07861215388368",
    id: "rateNOKJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "NOKSEK",
    symbolRate: "0.99652",
    id: "rateNOKSEK",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "NZDCAD",
    symbolRate: "0.833542258706407",
    id: "rateNZDCAD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "NZDCHF",
    symbolRate: "0.54678",
    id: "rateNZDCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "NZDJPY",
    symbolRate: "97.779",
    id: "rateNZDJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "NZDUSD",
    symbolRate: "0.60854",
    id: "rateNZDUSD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "SEKJPY",
    symbolRate: "15.1209",
    id: "rateSEKJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "SGDJPY",
    symbolRate: "118.324",
    id: "rateSGDJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "USDCAD",
    symbolRate: "1.36975",
    id: "rateUSDCAD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDCHF",
    symbolRate: "0.89856",
    id: "rateUSDCHF",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDCNH",
    symbolRate: "7.30321",
    id: "rateUSDCNH",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDCZK",
    symbolRate: "23.3577",
    id: "rateUSDCZK",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDHUF",
    symbolRate: "369.992",
    id: "rateUSDHUF",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDJPY",
    symbolRate: "160.68",
    id: "rateUSDJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
  {
    symbolName: "USDMXN",
    symbolRate: "18.43056",
    id: "rateUSDMXN",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDNOK",
    symbolRate: "10.64686",
    id: "rateUSDNOK",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDPLN",
    symbolRate: "4.02543227396646",
    id: "rateUSDPLN",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDRUB",
    symbolRate: "84.9974789445181",
    id: "rateUSDRUB",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDSEK",
    symbolRate: "10.62039",
    id: "rateUSDSEK",
    symbolDecimals: "4",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDSGD",
    symbolRate: "1.35793",
    id: "rateUSDSGD",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDTHB",
    symbolRate: "36.8",
    id: "rateUSDTHB",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDTRY",
    symbolRate: "32.8827",
    id: "rateUSDTRY",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "USDZAR",
    symbolRate: "18.4462",
    id: "rateUSDZAR",
    symbolDecimals: "5",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAGAUD",
    symbolRate: "43.609",
    id: "rateXAGAUD",
    symbolDecimals: "3",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAGEUR",
    symbolRate: "27.08",
    id: "rateXAGEUR",
    symbolDecimals: "3",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAGUSD",
    symbolRate: "29.0052",
    id: "rateXAGUSD",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAUAUD",
    symbolRate: "3499.95",
    id: "rateXAUAUD",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAUCHF",
    symbolRate: "1770.7",
    id: "rateXAUCHF",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAUEUR",
    symbolRate: "2172.8",
    id: "rateXAUEUR",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAUGBP",
    symbolRate: "1838.6",
    id: "rateXAUGBP",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XAUJPY",
    symbolRate: "373946.1",
    id: "rateXAUJPY",
    symbolDecimals: "0",
    pipsize: 0.01,
  },
  {
    symbolName: "XAUUSD",
    symbolRate: "2327.24",
    id: "rateXAUUSD",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XPDUSD",
    symbolRate: "928.12",
    id: "rateXPDUSD",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "XPTUSD",
    symbolRate: "1020.88",
    id: "rateXPTUSD",
    symbolDecimals: "2",
    pipsize: 0.0001,
  },
  {
    symbolName: "ZARJPY",
    symbolRate: "8.7024",
    id: "rateZARJPY",
    symbolDecimals: "3",
    pipsize: 0.01,
  },
];
