import React, { useState, useEffect } from 'react';
import { DataService } from '../../service/DataService';
import { currency_pairs } from '../../data/CurrencyPairs';
import ForexSessionClockComponent from '../forex-session-clock/ForexSessionClock';


const PairsInNewsComponent = () => {
    const [allSupportedCurrencies, setAllSupportedCurrencies] = useState([]);
    const [newsItems, setNewsItems] = useState([]);
    const [pairScores, setPairScores] = useState([]);
    const [mostPositivePairs, setMostPositivePairs] = useState([]);
    const [mostNegativePairs, setMostNegativePairs] = useState([]);
    const [mostNeutralPairs, setMostNeutralPairs] = useState([]);
    const [longPairs, setLongPairs] = useState([]);
    const [shortPairs, setShortPairs] = useState([]);
    const [activeFilter, setActiveFilter] = useState('');


    useEffect(() => {
        getSupportedCurrencies();
        getUpcomingNews();
        setNewsRelevance(true)
    }, []);

    useEffect(()=>{
        getTopTradePairs();
    },[pairScores])


    const getSupportedCurrencies = ()=>{
        let supportedCurrencies = [];
        currency_pairs.forEach((p) => {
            supportedCurrencies.push(p.symbolName);
        });
        setAllSupportedCurrencies(supportedCurrencies);
    }

    const getUpcomingNews = ()=>{
        DataService.getUpcomingNews().then(items => setNewsItems(items));
    }


    const isObjectEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0;
    };

    const getTopTradePairs = () => {
        const positivePairs = pairScores.filter((pair) => pair.pavg >= 3 && pair.navg >= -2);
        const negativePairs = pairScores.filter((pair) => pair.pavg <= 2 && pair.navg <= -3);
        const neutralPairs = pairScores.filter((pair) => (pair.pavg <= 2 && pair.navg >= -2) || Math.abs(pair.pavg - Math.abs(pair.navg)) <= 1);

        setMostPositivePairs(positivePairs);
        setMostNegativePairs(negativePairs);
        setMostNeutralPairs(neutralPairs);

        let longPairsList = [];
        positivePairs.forEach((e) => {
            negativePairs.forEach((k) => {
                let c = e.currency + k.currency;
                if (allSupportedCurrencies.indexOf(c) !== -1) {
                    longPairsList.push(c);
                }
            });

            neutralPairs.forEach((k) => {
                let c = e.currency + k.currency;
                if (allSupportedCurrencies.indexOf(c) !== -1) {
                    longPairsList.push(c);
                }
            });
        });
        setLongPairs(longPairsList);

        let shortPairsList = [];
        negativePairs.forEach((e) => {
            positivePairs.forEach((k) => {
                let c = e.currency + k.currency;
                if (allSupportedCurrencies.indexOf(c) !== -1) {
                    shortPairsList.push(c);
                }
            });

            neutralPairs.forEach((k) => {
                let c = e.currency + k.currency;
                if (allSupportedCurrencies.indexOf(c) !== -1) {
                    shortPairsList.push(c);
                }
            });
        });
        setShortPairs(shortPairsList);
    };

    const getBackgroundColor = (pair, index) => {
        const positiveScore = pair.pavg;
        const negativeScore = Math.abs(pair.navg);
        const scoreDivs = Array(10).fill('#eee');

        for (let i = 4; i >= 5 - negativeScore; i--) {
            let lightness = Math.max(10, 80 - (4 - i) * 15);
            scoreDivs[i] = `hsl(0, 100%, ${lightness}%)`; // Red
        }

        for (let i = 5; i < positiveScore + 5; i++) {
            let lightness = Math.max(10, 80 - (i - 5) * 15);
            scoreDivs[i] = `hsl(120, 100%, ${lightness}%)`; // Green
        }

        return scoreDivs[index];
    };

    const setNewsRelevance = (daily) => {
        
        if (daily) {
            setActiveFilter('DAILY');
            setPairScores([]);

            DataService.getPairsInActionForDay().then((response) => {
                const popularCurrencies = ['EUR', 'USD', 'CAD', 'CHF', 'AUD', 'GBP', 'JPY', 'NZD'];
                const filteredPairs = response.filter((k) => popularCurrencies.includes(k.currency));
                setPairScores(filteredPairs);
                
            });
        } else {
            setActiveFilter('WEEKLY');
            setPairScores([]);

            DataService.getPairsInAction().then((response) => {
                const popularCurrencies = ['EUR', 'USD', 'CAD', 'CHF', 'AUD', 'GBP', 'JPY', 'NZD'];
                const filteredPairs = response.filter((k) => popularCurrencies.includes(k.currency));
                setPairScores(filteredPairs);
                
            });
        }
    };

    const isWithinOneHour = (timestampInSeconds) => {
        const currentTime = Date.now(); // current time in milliseconds
        const oneHourFromNow = currentTime + 60 * 60 * 1000; // 1 hour in milliseconds
        const timestampInMilliseconds = timestampInSeconds * 1000; // convert given timestamp to milliseconds

        return timestampInMilliseconds >= currentTime && timestampInMilliseconds <= oneHourFromNow;
    };

    const convertTimestamp = (timestamp) => {
        const ms_timestamp = timestamp * 1000;
        const date = new Date(ms_timestamp);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        return date.toLocaleString('en-US', options);
    };

    return (
        <div id="pair-in-news-component">
            <div className="main_container">
                {/* <h4>Pairs in News</h4> */}
                
                
                <div className="wrapper">
                    <div className="time_filter">
                        <span className="filter_title">Relevance:&nbsp;</span>
                        <a
                            className={`filter_link ${activeFilter === 'DAILY' ? 'active' : ''}`}
                            onClick={() => setNewsRelevance(true)}
                        >
                            &lt; 24 HRS
                        </a>
                        &nbsp;|&nbsp;
                        <a
                            className={`filter_link ${activeFilter === 'WEEKLY' ? 'active' : ''}`}
                            onClick={() => setNewsRelevance(false)}
                        >
                            WEEKLY
                        </a>
                        <span style={{ paddingRight: '50px' }}></span>
                    </div>

                    <div className="currency_wrapper">
                        {isObjectEmpty(pairScores) && (
                            <div className="currency_list_item">
                                <h4>Low news volume</h4>
                                <div className="strength-meter">
                                    <div className="score-div"></div>
                                </div>
                            </div>
                        )}

                        {pairScores.map((pair, index) => (
                            <div className="currency_list_item" key={index}>
                                <h2>{pair.currency}</h2>
                                <div className="strength-meter">
                                    {[...Array(10)].map((_, i) => (
                                        <div
                                            className="score-div"
                                            key={i}
                                            style={{ backgroundColor: getBackgroundColor(pair, i) }}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="update_stats">
      <span className="filter_title">Last updated:&nbsp; </span>
      <span>12:00 CDT </span>
      <span style={{ paddingRight: '50px' }}></span>
    </div> */}

                    <div className="pair-suggestion-container">
                        <div className="left">
                            <div className="trade-pair-category">
                                <span>Long Bias</span>
                            </div>
                            <ul className="suggested-pair-list">
                                {longPairs.length > 0 ? (
                                    longPairs.map((pair, index) => (
                                        <li className="suggested-pair-list-item" key={index}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://www.tradingview.com/chart/?symbol=FX:${pair}&interval=15`}
                                            >
                                                {pair}
                                            </a>
                                        </li>
                                    ))
                                ) : (
                                    <li className="suggested-pair-list-item">
                                        Suggestions unavailable at this time!
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="right">
                            <div className="trade-pair-category">
                                <span>Short Bias</span>
                            </div>
                            <ul className="suggested-pair-list">
                                {shortPairs.length > 0 ? (
                                    shortPairs.map((pair, index) => (
                                        <li className="suggested-pair-list-item" key={index}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://www.tradingview.com/chart/?symbol=FX:${pair}&interval=15`}
                                            >
                                                {pair}
                                            </a>
                                        </li>
                                    ))
                                ) : (
                                    <li className="suggested-pair-list-item">
                                        Suggestions unavailable at this time!
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="news_container">
                    <div className="header">
                        <h2>Upcoming events</h2>
                    </div>

                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <td>Time</td>
                                    <td>Currency</td>
                                    <td>Title</td>
                                    <td>Impact</td>
                                    <td>Forecast</td>
                                    <td>Previous</td>
                                    <td>Actual</td>
                                </tr>
                            </thead>

                            <tbody>
                                {newsItems.map((item, index) => (
                                    <tr key={index}>
                                        <td
                                         style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative'
                                        }}
                                        >
                                            <div
                                                className="dot"
                                                title="Happening within 1 hr"
                                                style={{ display: isWithinOneHour(item.dateline) ? 'block' : 'none' }}
                                            >
                                                &nbsp;
                                            </div>
                                            {convertTimestamp(item.dateline)}
                                        </td>
                                        <td>{item.currency}</td>
                                        <td>{item.trimmedPrefixedName}&nbsp;</td>
                                        <td>{item.impactTitle}</td>
                                        <td>{item.forecast}</td>
                                        <td>{item.previous}</td>
                                        <td>{item.actual}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PairsInNewsComponent;