import axios from "axios";

const getAPIURL =()=>{
  
  let t = (new Date()).getTime() +"" ;
  // Get the current timestamp in milliseconds
  t= t.substring(5);
  return `https://fxverdict.com:3000?t=${t}`;
}

// Replace with your API URL

export const DataService = {
  getNews() {
    return axios.get(getAPIURL()).then((response) => response.data);
  },

  getUpcomingNews() {
    return axios.get(getAPIURL()).then((response) => {
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      let events = response.data;

      let evs = events.filter((e) => e.dateline >= currentTime);
      if (evs.length > 10) {
        evs = evs.slice(0, 10);
      }
      return evs;
    });
  },

  getPairsInAction() {
    return axios.get(getAPIURL()).then((response) => {
      const timestampInSeconds = Math.floor(Date.now() / 1000);
      const evs = response.data.filter((e) => e.dateline <= timestampInSeconds);

      const currencyMap = this.prepareCurrencyMap(evs);
      const scoreMatrix = this.analyseCurrencies(currencyMap);
      return this.analyzeMatrix(scoreMatrix);
    });
  },

  getPairsInActionForDay() {
    return axios.get(getAPIURL()).then((response) => {
      const timestampInSeconds = Math.floor(Date.now() / 1000);
      const last24hrNews = timestampInSeconds - 24 * 3600;
      const evs = response.data.filter(
        (e) => e.dateline >= last24hrNews && e.dateline <= timestampInSeconds
      );

      const currencyMap = this.prepareCurrencyMap(evs);
      const scoreMatrix = this.analyseCurrencies(currencyMap);
      return this.analyzeMatrix(scoreMatrix);
    });
  },

  prepareCurrencyMap(events) {
    const currenciesInNews = {};

    events.forEach((event) => {
      const currency = event.currency;
      if (!currenciesInNews[currency]) {
        currenciesInNews[currency] = [event];
      } else {
        currenciesInNews[currency].push(event);
      }
    });

    return currenciesInNews;
  },

  analyseCurrencies(currenciesInNews) {
    const scoreMatrix = [];
    const currencies = Object.keys(currenciesInNews);

    for (const currency of currencies) {
      const timestampInMilliseconds = Date.now();
      const c_events = currenciesInNews[currency];

      const lowImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("low") &&
          ev.dateline < timestampInMilliseconds
      );

      const highImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("high") &&
          ev.dateline < timestampInMilliseconds
      );

      const mediumImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("medium") &&
          ev.dateline < timestampInMilliseconds
      );

      const importantEvents = [
        ...mediumImpactCountEvents,
        ...highImpactCountEvents,
        // ...lowImpactCountEvents,
      ];

      if (importantEvents.length === 0) {
        continue;
      }

      const scores = importantEvents.map((ev) => ev.aiResponse);

      scoreMatrix.push({ currency, score: scores });
    }

    return scoreMatrix;
  },

  analyzeMatrix(mat) {
    const finalArray = [];

    for (const obj of mat) {
      const score_obj = {
        currency: obj.currency,
        pavg: 0,
        navg: 0,
      };

      let positiveScoresTotal = 0;
      let positiveScoreCount = 0;
      let negativeScoresTotal = 0;
      let negativeScoreCount = 0;

      obj.score.forEach((aiResp) => {
        if (aiResp.score >= 0) {
          positiveScoresTotal += aiResp.score;
          positiveScoreCount += 1;
        } else {
          negativeScoresTotal += aiResp.score;
          negativeScoreCount += 1;
        }
      });

      score_obj.pavg =
        positiveScoreCount === 0 ? 0 : positiveScoresTotal / positiveScoreCount;
      score_obj.navg =
        negativeScoreCount === 0 ? 0 : negativeScoresTotal / negativeScoreCount;

      finalArray.push(score_obj);
    }

    return finalArray;
  },
};
