import React, { useState } from 'react';


function SelectedPairNewsEventListComponent({ newsItems }) {
    const [visibilityStatus, setVisibilityStatus] = useState('HIDE');

    const toggleVisibilityStatus = () => {
        setVisibilityStatus(visibilityStatus === 'HIDE' ? 'SHOW' : 'HIDE');
    };

    const getActualValue = (actual) => {
        return parseFloat(actual.replace('%', ''));
    };

    const getClass = (item) => {
        const actualValue = getActualValue(item.actual);
        const prevValue = getActualValue(item.previous);
        if (actualValue > prevValue) {
            return 'green';
        } else if (actualValue < prevValue) {
            return 'red';
        } else {
            return '';
        }
    };

    const getForecastClass = (item) => {
        const forecastValue = getActualValue(item.forecast);
        const prevValue = getActualValue(item.previous);
        if (forecastValue > prevValue) {
            return 'green';
        } else if (forecastValue < prevValue) {
            return 'red';
        } else {
            return '';
        }
    };

    return (
        <div id="selected-pair-news-event-list-component">
            <div className="header-news-list">
                <h2>News events</h2>
                <h4 className="noselect" onClick={toggleVisibilityStatus}>
                    {visibilityStatus}
                </h4>
            </div>

            <div className="news-container" style={{ display: visibilityStatus == 'SHOW' ? 'none' : 'block' }}>
                <table>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Time</td>
                            <td>Currency</td>
                            <td>Title</td>
                            <td>Impact</td>
                            <td>Forecast</td>
                            <td>Previous</td>
                            <td>Actual</td>
                            <td>Explanation</td>
                        </tr>
                    </thead>

                    <tbody>
                        {newsItems.map((item,index) => (
                            <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item.timeLabel}</td>
                                <td>{item.currency}</td>
                                <td>{item.trimmedPrefixedName}&nbsp;</td>
                                <td>{item.impactTitle}</td>
                                <td className={getForecastClass(item)}>{item.forecast}</td>
                                <td>{item.previous}</td>
                                <td className={getClass(item)}>{item.actual}</td>
                                <td>
                                    {item.aiResponse.reasoning}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SelectedPairNewsEventListComponent;